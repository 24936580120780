import React, { useState, useEffect, useRef } from "react";
import { configuration } from "../../../helpers/config";
import { useSelector } from "react-redux";
import { Constants } from "../../../helpers/constants";

export default function RegionalDropdown({
  value,
  onChange,
  placeholderName,
  handleSelectChange,
  options,
  error,
  required,
  id,
  placeholder,
  label,
  setdealerconsentadress,
}) {
  const finalArray = [];

  options.forEach(item => {
    const level1Name = item.addressLevel1?.name;
    
    let group = finalArray.find(g => g.name === level1Name);
    
    if (!group) {
      // If the group does not exist, create a new one
      group = {
        name: level1Name,
        items: []
      };
      finalArray.push(group);
    }
    
    // Construct the address string based on configuration flags
    let addressString = item?.name || item?.dealerName;

    if (configuration.SHOW_DEALER_ADDRESS.LEVEL_1 === "true" && item?.addressLevel1?.name) {
      addressString += ` - ${item.addressLevel1.name}`;
    }
    if (configuration.SHOW_DEALER_ADDRESS.LEVEL_2 === "true" && item?.addressLevel2?.name) {
      addressString += ` - ${item.addressLevel2.name}`;
    }
    if (configuration.SHOW_DEALER_ADDRESS.LEVEL_3 === "true" && item?.addressLevel3?.name) {
      addressString += ` - ${item.addressLevel3?.name}`;
    }
    if (configuration.SHOW_DEALER_ADDRESS.LEVEL_4 === "true" && item?.addressLevel4?.name) {
      addressString += ` - ${item.addressLevel4?.name}`;
    }
    if (configuration.SHOW_DEALER_ADDRESS.LEVEL_5 === "true" && item?.addressLevel5?.name) {
      addressString += ` - ${item.addressLevel5?.name}`;
    }

    // Push the constructed address string into the group's items
    group.items.push({
      ...item,
      addressString
    });

    // Sort the group's items array based on item.name
   group.items.sort((a, b) => {
    if (a.name && b.name) {
      return a.name.localeCompare(b.name); // Sort in ascending order
    }
    return 0; // If name is undefined, keep the order unchanged
    });

    const customOrder = ["Miền Bắc", "Miền Trung", "Miền Nam"];

    // Sort the finalArray according to the custom order
    finalArray.sort((a, b) => {
      const indexA = customOrder.indexOf(a.name);
      const indexB = customOrder.indexOf(b.name);
      
      // If one of the names doesn't match, we'll put it at the end
      if (indexA === -1 && indexB === -1) {
        return 0; // Both names not in custom order, keep their order unchanged
      } else if (indexA === -1) {
        return 1; // A name not in custom order, put it at the end
      } else if (indexB === -1) {
        return -1; // B name not in custom order, put it at the end
      }
      
      return indexA - indexB; // Sort based on the index in customOrder
    });
  });

  const { is_dealer_selected, selected_dealer } = useSelector((state) => state.app);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(() => {
    if (placeholderName) {
      return placeholderName;
    } else
      return is_dealer_selected && selected_dealer && selected_dealer?.name
        ? selected_dealer?.name : Constants.forms.PREFFERED_DEALERSHIP ? Constants.forms.PREFFERED_DEALERSHIP : "-Preferred Dealership-";
  });
  const [border, setBorder] = useState(false);
  const [selectedOption, setSelectedOption] = useState(is_dealer_selected && selected_dealer && selected_dealer?.index ? selected_dealer?.index : 0);
  const focusRef = useRef();
  const sanitizedLabel = label.replace(/<[^>]*>/g, ''); // Removes all HTML tags

  useEffect(() => {
    if (open) {
      focusRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      });
    }
  }, [open]);

  const toggleDropdown = () => {
    setOpen((op) => !op);
    setBorder(true);
  };

  const handleSelectBoxBorder = () => {
    setOpen(false);
    setBorder(false);
  };

  const handlePlaceHolder = () => {
    onChange("");
    setName(placeholder);
    setSelectedOption(0);
    setOpen(false);
  };

  const handleOptions = (e, option) => {
    handleSelectChange(e);
    onChange(JSON.stringify(option));
    setOpen(false);
    setName(
      option.name || option.dealerName +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_1 === "true" && option?.addressLevel1?.name
        ? ` - ${option?.addressLevel1?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_2 === "true" && option?.addressLevel2?.name
        ? ` - ${option?.addressLevel2?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_3 === "true" && option?.addressLevel3?.name
        ? ` - ${option?.addressLevel3?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_4 === "true" && option?.addressLevel4?.name
        ? ` - ${option?.addressLevel4?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_5 === "true" && option?.addressLevel5?.name
        ? ` - ${option?.addressLevel5?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_1 === "true" && option?.value?.addressLevel1?.name
          ? ` - ${option?.value?.addressLevel1?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_2 === "true" && option?.value?.addressLevel2?.name
          ? ` - ${option?.value?.addressLevel2?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_3 === "true" && option?.value?.addressLevel3?.name
        ? ` - ${option?.value?.addressLevel3?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_4 === "true" && option?.value?.addressLevel4?.name
        ? ` - ${option?.value?.addressLevel4?.name}` : "") +
      (configuration.SHOW_DEALER_ADDRESS.LEVEL_5 === "true" && option?.value?.addressLevel5?.name
        ? ` - ${option?.value?.addressLevel5?.name}` : "")
    );
  };

  return (
    <div className="custom-dropdown" onMouseLeave={handleSelectBoxBorder}>
      <div className="label-text">
        <span dangerouslySetInnerHTML={{ __html: sanitizedLabel }} />
        {required && <span>*</span>}
      </div>
      <div style={error ? { border: "2px solid #FF9494" } : {}} className={`selected-option ${border ? "select-border" : ""}`} onClick={toggleDropdown}>
        {placeholderName ? name : name.toUpperCase()}
      </div>
      {error && <p className="error">{error}</p>}
      {open && (
        <div className="dropdown-options">
          <div className="option-default" onClick={handlePlaceHolder}>
            {placeholder}
          </div>
          {finalArray?.map((group, groupIndex) => (
            <div key={group.name} className="group">
              <div className="group-name font-bold ml-2 mt-1">{group.name}</div>
              {group.items.map((option, index) => (
                <div
                  key={option.name || option.dealerName}
                  id={id}
                  className="option ml-2"
                  onClick={(e) => {
                    handleOptions(e, option);
                    setSelectedOption(groupIndex + index + 1);
                    setdealerconsentadress(option);
                  }}
                  onMouseLeave={(e) => handleSelectChange(e)}
                  style={selectedOption === groupIndex + index + 1 ? { backgroundColor: 'lightgray', color: '#000' } : {}}
                  ref={selectedOption === groupIndex + index + 1 ? focusRef : undefined}
                >
                  {option.name || option.dealerName}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_1 == "true" && option?.addressLevel1?.name
                    ? ` - ${option.addressLevel1?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_2 == "true" && option?.addressLevel2?.name
                    ? ` - ${option.addressLevel2?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_3 == "true" && option?.addressLevel3?.name
                    ? ` - ${option.addressLevel3?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_4 == "true" && option?.addressLevel4?.name
                    ? ` - ${option.addressLevel4?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_5 == "true" && option?.addressLevel5?.name
                    ? ` - ${option.addressLevel5?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_1 == "true" && option?.value?.addressLevel1?.name
                    ? ` - ${option?.value?.addressLevel1?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_2 == "true" && option?.value?.addressLevel2?.name
                    ? ` - ${option?.value?.addressLevel2?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_3 == "true" && option?.value?.addressLevel3?.name
                    ? ` - ${option?.value?.addressLevel3?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_4 == "true" && option?.value?.addressLevel4?.name
                    ? ` - ${option?.value?.addressLevel4?.name}` : ""}
                  {configuration.SHOW_DEALER_ADDRESS.LEVEL_5 == "true" && option?.value?.addressLevel5?.name
                    ? ` - ${option?.value?.addressLevel5?.name}` : ""}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}