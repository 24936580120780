import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import InputField from "../utils/inputfield";
import Button from "../utils/formbutton";
import TitleText from "../utils/TitleText";
import TestDriveStyle from "../test-drive/testdrive.module.css";
import {
  formApiHandler,
  send_to_dealer_mail_configurator,
} from "../../../utils/FormApiHandler";
import SelectInput from "../utils/selectinput";
import axios from "axios";
import { configuration } from "../../../helpers/config";
import ReCAPTCHA from "react-google-recaptcha";
import {
  DEALER_DATA_FORM,
  CAR_CONFIGURATOR_SUBMIT,
  PERSONAL_IDENTIFIALBLE_FORM_RES,
} from "../../../redux/ActionTypes";
import { useRouter } from "next/router";
import {
  purchaseOptions,
  communicationOptions,
  currentTimeSlots,
  downPaymentOptions,
  EmailValidation,
  formNames,
  selectFinanceOptions,
  NameValidation,
  PhoneValidation,
  planningToPurchaseOptions,
  TextValidation,
  timeSlots,
  inquiryTypes,
  ServicesTypes
} from "../Validation";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { useForm, Controller } from "react-hook-form";
import { today, tomorrowDate } from "../../../utils/GenUtils";
import moment from "moment";
import LocationSearchInput from "../../SearchInputLocation";
import { Constants } from "../../../helpers/constants";
import { formTitle } from "../Validation";
import Cookies from "js-cookie";
import { DevTool } from "@hookform/devtools";
import PhoneOrInputConsent from "./PhoneOrInputConsent";
import { twoWheelerData } from "../../../utils/dummyData";
import CustomDropdown from "../utils/CustomDropdown";
import RegionalDropdown from "../utils/RegionalDropdown";
import { UTM_ID_FROM_SOURCE } from "./utm";

const DYNAMIC_FORM_CONSTANT = Constants.forms;

 // Helper function to convert ArrayBuffer to Hex string
 const toHex = (buffer) => [...new Uint8Array(buffer)]
 .map((b) => b.toString(16).padStart(2, '0'))
 .join('');

// Function to hash input using SHA-256
const hashInput = async (input) => {
 const encoder = new TextEncoder();
 const data = encoder.encode(input);
 const hashBuffer = await crypto.subtle.digest('SHA-256', data);
 return toHex(hashBuffer);
};

 
const DynamicFormComponent = ({
  data,
  onClose,
  hidetitle,
  is_share_brochure,
}) => {
  //dealer data from redux, if available
  const form_dealer_data = useSelector(({ app }) => app.dealers);
  const LobValue=useSelector(state=>state?.app?.LobValue)
  const [modelData, setModelData] = useState([]);
  const [dealerData, setDealerData] = useState(
    form_dealer_data.length > 0 ? form_dealer_data : []
  );
  const [variants, setVariants] = useState([]);
  const [selectedmodel, setselectedmodel] = useState();
  const [loading, setLoading] = useState(false);
  const [fieldsData, setFieldsData] = useState([]);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [modelDisabled, setModelDisabled] = useState(false);
  const [variantDisabled, setVariantDisabled] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const [dealerconsentadress, setdealerconsentadress] = useState({});
  const [suzukiApproval,setsuzukiApproval] = useState()
  const [marketing_channels,setmarketing_channels] = useState()
  // const [carConfigModelData,setCarConfigModelData] = useState
  const reduxData = useSelector(({ app }) => app);
  const router = useRouter();
  const dispatch = useDispatch();
  //auto fill configurator details starts
  // const car_configurator_data = useSelector(({ app }) => app.configurator_data);
  const car_configurator_data = reduxData?.configurator_data;
  const send_dealer_configurator =
    reduxData?.formModals?.send_to_dealer_configurator;
  const personal_identifiable_form = reduxData?.formModals?.pii;

  const [selectAll, setSelectAll] = useState(false);
  const [All, setAll] = useState(false);
  const [serviceModelsData, setServiceModelsData] = useState([]);
  const [dealers2w, setDealers2w] = useState(
    form_dealer_data.length > 0 ? form_dealer_data : []
  );
  const [showOtherModelInput, setShowOtherModelInput] = useState(false);
  const [marketingFields, setmarketingFields] = useState([]);
  const [InputFields, setInputFields] = useState([]);
  const [showWaning, setshowWarning] = useState(false);
  const [checkboxDisable, setcheckboxDisable] = useState(true);
  const [ischecked, setischecked] = useState(false);
  const [Both, setBoth] = useState(false);
  const [warn, setwarn] = useState(false);
  const [directDisable, setdirectDisable] = useState(true);
  const [vault, setvault] = useState(false);
  const [first, setnes] = useState(false);
  const [second, setses] = useState(false);
  // const[setDirectMarketingConsent,setDirectMarketingConsent]=useState()
  // const [warn,setwarn] =useState(false);
  const [is2WDealer, setIs2wDealer] = useState(false);
  const [utmMedium,setUtmMedium]=useState("");
  const [utmSource,setUtmSource]=useState("");
  const [utmSourceId,setUtmSourceId]=useState("");  
  const [consentDisplayNames,setconsentDisplayNames]=useState({})
  const[dealerDetail, setdealerDetail]=useState('')
  const [path,setPath]=useState("");
  const [isFormSubmitted,setIsFormSubmitted]=useState(false);
  const [brochureType,setbrochureType]=useState('');
  const [formsData, setFormsData] = useState([]);
  const [finalModel,setFinalModel]= useState([]);
  const [modelCodePage, setModelCodePage] = useState([]);
  const [isLocationOn, setIsLocationOn] = useState(null);

  // const reduxData = useSelector(({ app }) => app);
  // const router = useRouter();

  //   Managing and organizing form data on component mount and data change.
  //   Also filtering the data as genral inputfields and marketing inputfields.

  const Lob_Value=useSelector(state=>state?.app?.LobValue)


  //   Managing and organizing form data on component mount and data change.
  //   Also filtering the data as genral inputfields and marketing inputfields.
  useEffect(()=>{
    if(window){
      setPath(new URL(window.location).pathname);
    }
  },[])
  // UseEffct for form fields.

  useEffect(() => {
    //selecting fields that are not in marketing section . (other than marketing feilds)
    const formfields =
      data &&
      data?.fields?.filter((col) => {
        return (
          col.field_name !== "marketing_email_consent" &&
          col.field_name !== "marketing_sms_consent" &&
          col.field_name !== "marketing_phone_consent" &&
          col.field_name !== "marketing_consent_via_distributor" &&
          col.field_name !== "marketing_consent_via_dealer" &&
          col.field_name !== "contact_consent" &&
          col.field_name !== "combined_consent" &&
          col.field_name !== "marketing_channels"

        );
      });

    // selecting term field
    const objToMove =
      (formfields && formfields.find((obj) => obj.field_name === "terms")) ||
      null;

    const marketingSection =
      formfields &&
      formfields.find((e) => {
        return e.field_name === "direct_marketing_consent";
      });

    //selecting all the checkbox fields
    const checkboxFields =
      formfields &&
      formfields.filter(
        (obj) =>
          obj.field_name !== "terms" &&
          obj.input_type === "checkbox" &&
          obj.field_name !== "direct_marketing_consent"
      );
      checkboxFields && checkboxFields?.map((item)=>{
        setconsentDisplayNames((prevConsentDisplayNames) => ({
          ...prevConsentDisplayNames,
          [item?.field_name == "dealer_consent" ? "share_personal_details_with_dealer" : item?.field_name]: item?.field_name == "dealer_consent" ? item?.display_name + dealerDetail :item?.display_name
        }));
      })
    // all input fields other than checkbox ,terms and marketing section.
    const filteredData =
      (formfields &&
        formfields.filter(
          (obj) => obj.field_name !== "terms" && obj.input_type !== "checkbox"
        )) ||
      [];

    //
    // updated data variable.
    // the desired order for forms field is -> 1. all the inputs , 2.All the checkbox, 3.terms.
    const newData =
      objToMove && marketingSection
        ? [...filteredData, marketingSection, ...checkboxFields, objToMove]
        : checkboxFields && marketingSection
        ? [...filteredData, marketingSection, ...checkboxFields,objToMove]
        : filteredData && checkboxFields
        ? [...filteredData, ...checkboxFields,objToMove]
        : [...filteredData];

    setFieldsData([...newData]);
    setInputFields([...newData]);
  }, [data,dealerDetail]);

  useEffect(() => {
    // Check if geolocation is supported
    if ("geolocation" in navigator) {
      // Get the location to check if geolocation is enabled
      navigator.geolocation.getCurrentPosition(
        () => {
          setIsLocationOn(true); // Geolocation is enabled
        },
        () => {
          setIsLocationOn(false); // Geolocation is disabled or permission is denied
        }
      );
    } else {
      setIsLocationOn(false); // Geolocation is not supported
    }
  }, []);

  function hasDirectMarketingConsent(data) {
    // Check if data object and fields array exist
    if (data && Array.isArray(data.fields)) {
      // Iterate over the fields array
      for (const field of data.fields) {
        // Check if field_name is "direct_marketing_consent"
        if (field.field_name === "direct_marketing_consent") {
          return true; // Return true if found
        }
      }
    }
    return false; // Return false if not found
  }
  //useeffect for marketing fields
  useEffect(() => {
    // filtering all marketing fields.
    const otherdata1 =
      data &&
      data?.fields?.filter((col) => {
        return (
          col.field_name === "marketing_email_consent" ||
          col.field_name === "marketing_sms_consent" ||
          col.field_name === "marketing_phone_consent" ||
          col.field_name === "marketing_consent_via_distributor" ||
          col.field_name === "marketing_consent_via_dealer" ||
          col.field_name === "contact_consent" ||
          col.field_name === "combined_consent" ||
          col.field_name === "marketing_channels"
        );
      });
      otherdata1 && otherdata1?.map((item)=>{
        setconsentDisplayNames((prevConsentDisplayNames) => ({
          ...prevConsentDisplayNames,
          [item?.field_name]: item?.field_name == "marketing_consent_via_dealer" ? item?.display_name + dealerDetail : item?.display_name
        }));
      })

    // find main marketing consent box.
    // const Agreeconsent =
      otherdata1 &&
      otherdata1.find((obj) => obj.field_name === "direct_marketing_consent");
    //  filtering form_suzuki and preferrend_dealership_consents.
    const ItemToMove =
      (otherdata1 &&
        otherdata1.filter(
          (obj) =>
            obj.field_name === "combined_consent" 
        )) ||
      null;

      const Infobox =
      (otherdata1 &&
        otherdata1.filter(
          (obj) =>
            obj.field_name === "contact_consent" 
        )) ||
      null;

      const suzukiApproval1 = (otherdata1 &&
        otherdata1.filter(
          (obj) =>
            obj.field_name === "marketing_consent_via_distributor" ||
            obj.field_name === "marketing_consent_via_dealer"
        )) ||
      null;
      setsuzukiApproval(suzukiApproval1)

    // filtering the all marketing consents.
    const marketingChannel =
      otherdata1 &&
      otherdata1.find((obj) => obj.field_name === "marketing_channels");

    const AllMarketingConsents =
      (otherdata1 &&
        otherdata1.filter(
          (obj) =>
            obj.field_name === "marketing_phone_consent" ||
            obj.field_name === "marketing_sms_consent" ||
            obj.field_name === "marketing_email_consent"
        )) ||
      null;
      setmarketing_channels(AllMarketingConsents)
    // the desired order for forms field is ->1.Main consents 2. form_suzuki and preferrend_dealership consents in marketing section  ,3.phone call marketing consents and All marketing consent.
    const MarketingFieldData =
      ItemToMove
        ? [...Infobox,marketingChannel, ...ItemToMove]
        : ItemToMove && marketingChannel
        ? [...Infobox,marketingChannel, ...ItemToMove]
        : ItemToMove
        ? [...Infobox,marketingChannel, ...ItemToMove]
        : null;

    setmarketingFields(MarketingFieldData);
  }, [data,dealerDetail]);

  // validation using use-form-hook.
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    trigger,
    resetField,
    watch,
    control,
    setValue,
  } = useForm({
    mode: "all",
  });

  const values = watch();

  useEffect(()=>{
    if(values.outletId){
      const dealerType = values.outletId
      ? "outletId"
      : values["2w_dealer"]
        ? "2w_dealer"
        : null;

    const dealerData = values[dealerType] && JSON.parse(values[dealerType]);
    // setdealerDetail(`${'('+dealerData?.name+' - '+dealerData?.addressLine1+')'}`)
    setdealerDetail(
      dealerData &&
      dealerconsentadress &&
      (
        '(' +
        dealerconsentadress?.name +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_1 === "true" && dealerconsentadress?.addressLevel1
          ? ` - ${dealerconsentadress?.addressLevel1?.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_2 === "true" && dealerconsentadress?.addressLevel2
          ? ` - ${dealerconsentadress?.addressLevel2?.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_3 === "true" && dealerconsentadress?.addressLevel3
          ? ` - ${dealerconsentadress?.addressLevel3?.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_4 === "true" && dealerconsentadress?.addressLevel4
          ? ` - ${dealerconsentadress?.addressLevel4?.name}`
          : "") +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_5 === "true" && dealerconsentadress?.addressLevel5
          ? ` - ${dealerconsentadress?.addressLevel5?.name}`
          : "") +
        ')'
      )
    );
    }else{
    setdealerDetail('')
    }
  },[values.outletId])
  const allarray = Object.keys(values).filter((e) => {
    return (
      e !== "marketing_email_consent" &&
      e !== "marketing_sms_consent" &&
      e !== "marketing_phone_consent" &&
      e !== "direct_marketing_consent" &&
      e !== "marketing_consent_via_distributor" &&
      e !== "marketing_consent_via_dealer"
    );
  });
  // list of consent variables that needs to be watched for changes

  const watchConsents = watch([
    "phone_consent",
    "data_privacy_consent",
    "email_consent",
    "call_centre_consent",
    "tnc_consent",
    "privacy_policy_consent",
    "phone_or_email_consent",
    "phone_or_consent",
    "email_or_consent",
    "dealer_consent",
    "terms",
  ]);

  //for handling select all funcitonality
  useEffect(() => {
    // if(send_dealer_configurator || send_dealer_configurator=="true") return ;//for car configurator it will return from here when send to dealer button is clicked
    if (watchConsents.includes(false)) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  }, [watchConsents]);

  const marketingvalues = watch();
  const allMarketingarray = Object.keys(marketingvalues).filter((e) => {
    return (
      e === "marketing_email_consent" ||
      e === "marketing_sms_consent" ||
      e === "marketing_phone_consent"
    );
  });

  // list of marketing consent variables that needs to be watched for changes.
  const watchMarketingConsents = watch([
    "marketing_email_consent",
    "marketing_sms_consent",
    "marketing_phone_consent",
  ]);

  // To remove the warning after checked any marketing consents.
  useEffect(() => {
    if (watchMarketingConsents.includes(true)) {
      setischecked(false);
    } else {
      setischecked(true);
    }
  }, [watchMarketingConsents]);

  useEffect(() => {
    if (watchMarketingConsents.includes(false)) {
      setcheckboxDisable(false);
    }
  }, [ischecked]);

  useEffect(() => {
    if (watchMarketingConsents.includes(true)) {
      setshowWarning(false);
    } else if (watchMarketingConsents.includes(false)) {
      setshowWarning(true);
    }
  }, [watchMarketingConsents]);

  //for handling All named checkbox funcitonality in marketing section.
  useEffect(() => {
    if (watchMarketingConsents.includes(false)) {
      setAll(false);
    } else {
      setAll(true);
    }
  }, [watchMarketingConsents]);

  //logic for select the both name checkbox automatically if two box are already selected
  const watchBoth = watch([
    "marketing_consent_via_distributor",
    "marketing_consent_via_dealer",
  ]);
  useEffect(() => {
    if (watchBoth.includes(false)) {
      setBoth(false);
    } else {
      setBoth(true);
    }
  }, [watchBoth]);

  useEffect(() => {
    if (watchBoth.includes(true)) {
      setwarn(true);
    } else {
      setwarn(false);
    }
  }, [watchBoth]);

  useEffect(() => {
    // Check if the fields data contains 'service_model' or not
    let containsServiceModel = fieldsData.some(
      (field) => field?.field_name === "service_model"
    );

    // Check if the fields data contains '2w_dealer' or not
    let contains2wDealer = fieldsData.some(
      (field) => field?.field_name === "2w_dealer"
    );

    // Fetch and set service models data if 'service_model' is present in the fields data
    if (containsServiceModel) {
      (async () => {
        try {
          const res = await axios.get(
            `${configuration.COMMON_MASTER_SUZUKI}models?lob=1`,
            {
              headers: {
                "x-api-key": configuration.COMMON_MASTER_KEY,
              },
            }
          );
          let allData = res.data.data;
          // Filter service models data to include only those that are active or have an 'INACTIVE' code
          let serviceActiveModels = allData.filter(
            (item) => item.status === true
          );
          let serviceOtherModel = allData.filter(
            (item) => item.code === "INACTIVE"
          );
          setServiceModelsData([...serviceActiveModels, ...serviceOtherModel]);
        } catch (e) {
          console.error(e.message, "Error with models API");
        }
      })();
    }
    setIs2wDealer(contains2wDealer);

    // Fetch and set 2-wheeler dealers data if '2w_dealer' is present in the fields data
    if (!form_dealer_data && contains2wDealer) {
      (async () => {
        try {
          const res = await axios.get(
            `${configuration.DEALER_URL_ALT}?longitude=${configuration.COUNTRY_DEFAULT_LONG}&latitude=${configuration.COUNTRY_DEFAULT_LAT}&radius=${configuration.DEFAULT_MAP_RADIUS}&lob=${configuration.COMMON_MASTER_LOB_2W}&status=true`,
            configuration.HEADER_DEALER_LOCATOR
          );
          setDealers2w(res?.data?.data);
        } catch (e) {
          console.error(e.message);
        }
      })();
    }
  }, [fieldsData]);

  useEffect(() => {
    // Check if a dealer is selected in Redux state
    if (reduxData.is_dealer_selected) {
      // If a dealer is selected, set the 'outletId' value to the selected dealer
      if (is2WDealer) {
        setValue("2w_dealer", JSON.stringify(reduxData.selected_dealer));
      } else {
        setValue("outletId", JSON.stringify(reduxData.selected_dealer));
      }
    }
    const fetchFormsAndModels = async () => {
      try {
        let allModels = [];
        const formModelsMap = {}; // Map to store models by form_name
  
        if (reduxData.dealerCode !== "") {
          // Fetch models data
          const modelsRes = await axios.get(
            `${configuration.APP_URL_PRODUCTS}model?lob=${Lob_Value}`
          );
          allModels = modelsRes?.data?.data || [];
          // Filter models based on modelOrders.modelName
          if (data?.fields && Array.isArray(data.fields)) {
            data.fields.forEach((field) => {
              if (field.field_name === "model_code" && Array.isArray(field?.modelOrders)) {
                // Extract model names from modelOrders
                const modelNames = field.modelOrders.map((order) => order.modelName);
                const matchingModels = modelNames.map((modelName) => {
                  // Find the corresponding model in allModels based on modelName
                  const model = allModels.find(
                    (model) => model?.model_name === modelName 
                  );
    
                  if (model) {
                    // Find the corresponding orderIndex from modelOrders
                    const order = field.modelOrders.find(
                      (order) => order.modelName === modelName
                    );
    
                    if (order?.showModel !== false) { // Include model if showModel is either true or undefined
                      return { 
                        ...model,
                        showModel: order?.showModel 
                      };
                    }
                  }
    
                  return null; // Return null if the model wasn't found
                }).filter(Boolean); // Remove null entries from the array
                  
                  const filteredModels = allModels.filter((model) => model?.show_in_dropdown === undefined || model?.show_in_dropdown === true);

                  // Filter out models that are in modelNames
                  const modelsNotInModelNames = filteredModels.filter((model) => {
                    return !modelNames.includes(model.model_name);
                  });

                  // Combine matching models and modelsNotInModelNames if the latter is not empty
                  let combinedModels = matchingModels;
                  if (modelsNotInModelNames.length > 0) {
                    // Concatenate modelsNotInModelNames with matchingModels
                    combinedModels = [...matchingModels, ...modelsNotInModelNames];
                  }
                  console.log("modelCombined", combinedModels)
                  // Sort matching models by the orderIndex (if it exists)
                  const sortedModels = combinedModels.sort(
                    (a, b) => a.orderIndex - b.orderIndex
                  );  
                  // Store sorted models in the formModelsMap
                  formModelsMap[field.form_name] = sortedModels;
              }else if(field.field_name === "model_code"){
                const filteredModels = allModels.filter(model => model?.show_in_dropdown===undefined || model?.show_in_dropdown === true);
                formModelsMap[field.form_name] = filteredModels;
              }
            });
    
            setFinalModel(formModelsMap); // Update state with models mapped to forms
          } else {
            setFinalModel({ allModels });
          }
  
        // If no forms have form_name, list all models
        if (Object.keys(formModelsMap).length === 0) {
          setFinalModel({ allModels });
        } else {
          setFinalModel(formModelsMap); // Update state with models mapped to forms
        }
      }

        // Check if the 'brand' query parameter is present in the router
        if (router.query.brand !== undefined) {
          // Map through the model data and find the item with a matching 'page_url' to the 'brand' query parameter
          const updatedModels = allModels.map((item) => {
            if (item.page_url === router.query.brand) {
              // If a match is found, set the 'model_code' value and update variants
              setValue("model_code", JSON.stringify(item));
              setVariants(item.variants);
              setModelCodePage(item.model_code)
              return { ...item, selected: true };
            } else {
              return { ...item };
            }
          });
       setModelData(updatedModels);
          setModelDisabled(true);
        } else if (
          send_dealer_configurator ||
          send_dealer_configurator == "true" ||
          personal_identifiable_form ||
          personal_identifiable_form == "true"
        ) {
          let newModelsData = res?.data?.data?.map((item) => {

            if (
              item.model_code == car_configurator_data.model_data.model_code
            ) {

              let variantsData = item.variants
                .filter(
                  (item) =>
                    item.variant_code ===
                    car_configurator_data.variant_data.variant_code
                )
                .map((item) => {
                  return { ...item, selected: true };
                });
              setVariants(variantsData);
              setValue("model_code", JSON.stringify(item));
              setValue("variant_code", JSON.stringify(variantsData[0]));
              setModelCodePage(item.model_code);
              return { ...item, selected: true };
            } else {
              return item;
            }
          });
          setModelData(newModelsData);
          setModelDisabled(true);
          setVariantDisabled(true);
        } else {
          setModelData(allModels);
        }
      } catch (e) {
        console.error(e.message, "Error with models API");
      }
    };
    fetchFormsAndModels();
  }, [
    reduxData.is_dealer_selected,
    reduxData.selected_dealer,
    router.query.brand,
    is2WDealer,
  ]);
  
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const utmSourceLocal = queryParams.get("utm_source");
    const utmMediumLocal = queryParams.get("utm_medium");
    const utmSourceIdLocal = UTM_ID_FROM_SOURCE(utmSourceLocal);
    setUtmSource(utmSourceLocal ? utmSourceLocal : "");
    setUtmMedium(utmMediumLocal ? utmMediumLocal : "");
    //setUtmSourceId(utmSourceIdLocal ? utmSourceIdLocal : "");
    if (utmSourceIdLocal) {
      setUtmSourceId(utmSourceIdLocal);
    } else if (document.referrer) {
      const searchEngines =
        /google\.|bing\.|yahoo\.|baidu\.|yandex\.|duckduckgo\.|ask\.|naver\./i;
      if (searchEngines.test(document.referrer)) {
        setUtmSourceId("1717588131778");
      } else {
        setUtmSourceId("1717588141715");
      }
    } else if (!document.referrer) {
      //direct traffic
      setUtmSourceId("1717588141715");
    }
    else {
      setUtmSourceId("1717588141715");
    }  
  }, []);
   
  const formNameCountry=(formData)=>{
    switch(process.env.NEXT_PUBLIC_COUNTRY_CODE){
      case "MEX":
        switch(formData?.form_name){
          case "test_drive_form":
            return {...formData,form_name:"SMM+TD"}
              // setFormNameCountry("SMM+TD");
              break;
          case "finance_form":
            return {...formData,form_name:"SMM+FI"}
              // setFormNameCountry("SMM+FI");
              break; 
          case "enquiry_form":
            return {...formData,form_name:"SMM+CALL"}
              // setFormNameCountry("SMM+CALL");
              break;
          case "get_quote_form":
            return {...formData,form_name:"SMM+Quote"}
              // setFormNameCountry("SMM+Quote");
              break;
          case "brochure_form":
            return {...formData,form_name:"SMM+BR"}
              // setFormNameCountry("SMM+BR");
              break;
        }
        break;
      default:
        return formData
    }
  }

  const onSubmit = async (formValues) => {
    setIsFormSubmitted(true)

    // Format the date using the moment library
    let formattedDate = moment(formValues?.p_date).format("YYYY-MM-DD");
    // Check if CAPTCHA is enabled and if it's valid
    if (data?.enable_captcha && !isCaptchaValid) {
      toast.error(DYNAMIC_FORM_CONSTANT.COMPLETE_CAPTCHA);
      return;
    }
    
     // Destructure to remove "selected_dealer" from formValues
     const { selected_dealer, ...filteredFormValues } = formValues;

    // Lead Mode mapping object for every form
    const LeadModeID = {
      Enquiry_Form: "1710746720310",
      Brochure_Form: "1710746592014",
      Test_Drive_Form: "1710746578356",
      Get_Quote_Form: "1710746515071",
      Brochure_Form_2w: "1710746746988",
      Contact_Form: "1710746645810",
      Service_Form: "1710746896450",
    };

    // Default value of leadMode
    let leadMode = "Website"; 

    // Lead mode name based on the lead_mode_id
    for (const [key, value] of Object.entries(LeadModeID)) {
      if (data.lead_mode_id === value) {
        leadMode = key;
        break;
      }
    }

    // Prepare the form data to be sent in the request
    let formData = {
      ...filteredFormValues,
      share_personal_details_with_dealer:
        formValues.share_personal_details_with_dealer === true ? 1 : 0,
      phone_or_email_consent:formValues.phone_or_email_consent === true ? 1 : 0,
      data_privacy_consent:formValues.data_privacy_consent === true ? 1 : 0,
      terms : formValues.terms === true ? 1 : 0,
      email_reminder_date:
        formValues.p_date &&
        formValues.p_time &&
        `${formattedDate}T${JSON.parse(formValues.p_time).value}:00:00.000Z`,
      preferred_date_time:
        formValues.p_date &&
        formValues.p_time &&
        `${formattedDate}T${JSON.parse(formValues.p_time).value}:00:00.000Z`,
      first_name: formValues.name || formValues.first_name,
      Phone:formValues.phone || formValues.Phone || formValues.calling_no,
       // outletId: formValues["2w_model"]
      //   ? formValues["2w_dealer"] && JSON.parse(formValues["2w_dealer"]).code
      //   : formValues.outletId && JSON.parse(formValues.outletId).code,
      outletId: formValues["2w_model"]
        ? formValues["2w_dealer"] && JSON.parse(formValues["2w_dealer"]).code
        :  formValues["selected_dealer"] ? JSON.parse(formValues["selected_dealer"]).dealerCode  : formValues.outletId && JSON.parse(formValues.outletId).code ,
      communication_medium_id:
        formValues.communication_mode &&
        JSON.parse(formValues.communication_mode).value,
      finance_option:
        formValues.finance_option &&
        JSON.parse(formValues.finance_option).value,
      purchase_type:
        formValues.purchase_type && JSON.parse(formValues.purchase_type).value,
      planning_to_purchase_in:
        formValues.planning_to_purchase &&
        JSON.parse(formValues.planning_to_purchase).code,
      model_code: formValues["2w_model"]
        ? formValues["2w_model"] &&
          JSON.parse(formValues["2w_model"]).model_code
        : formValues.model_code && JSON.parse(formValues.model_code).model_code,
      model: formValues["2w_model"]
        ? formValues["2w_model"] &&
          JSON.parse(formValues["2w_model"]).model_name
        : formValues.model_code && JSON.parse(formValues.model_code).model_name,
      service_model:
        formValues.service_model && JSON.parse(formValues.service_model).name,
      service_model_code:
        formValues.service_model && JSON.parse(formValues.service_model).code,
      lob:parseInt(LobValue) ,
      form_name: data?.form_name,
      p_time: formValues.p_time && JSON.parse(formValues.p_time).value,
      down_payment:
        formValues.down_payment && JSON.parse(formValues.down_payment).value,
      variant_code:
        formValues.variant_code &&
        JSON.parse(formValues.variant_code).variant_code,
      variant:
        formValues.variant_code &&
        JSON.parse(formValues.variant_code).variant_name,
      business_id: data.business_id,
      lead_source_id: data.lead_source_id,
      lead_mode_id: data.lead_mode_id,
      lead_mode: leadMode,

      phone_consent: Number("1"),
      email_consent: Number("1"),
      call_centre_consent:
        formValues.call_centre_consent == true ? Number("1") : Number("0"),
      tnc_consent: formValues.tnc_consent == true ? Number("1") : Number("0"),
      privacy_policy_consent:
        formValues.privacy_policy_consent == true ? Number("1") : Number("0"),
      remarks: formValues.otherModelName
        ? formValues.otherModelName
        : formValues.remarks,
      marketing_consent_via_distributor:
        formValues.marketing_consent_via_distributor == true
          ? Number("1")
          : Number("0"),
      marketing_consent_via_dealer:
        formValues.marketing_consent_via_dealer == true
          ? Number("1")
          : Number("0"),
      marketing_phone_consent:
        formValues.marketing_phone_consent == true ? Number("1") : Number("0"),
      marketing_sms_consent:
        formValues.marketing_sms_consent == true ? Number("1") : Number("0"),
      marketing_email_consent:
        formValues.marketing_email_consent == true ? Number("1") : Number("0"),
      direct_marketing_consent: hasDirectMarketingConsent(data)
        ? Number("1")
        : Number("0"),
      // formValues.direct_marketing_consent == true ? Number("1") : Number("0"),
      is_share_brochure:
        data.form_name == "brochure_form" ||
        data.form_name == "brochure_form_2w"
          ? Number("1")
          : Number("0"),
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_source_id: String(utmSourceId),
      cmp_labels : consentDisplayNames,
      customer_type:formValues.vehicle_quantity && formValues.vehicle_quantity !=undefined || typeof formValues.vehicle_quantity!="undefined" ?"fleet":"",
      service_type:formValues?.service_type?JSON.parse(formValues.service_type).name:"",
      service_type_id:formValues?.service_type?JSON.parse(formValues.service_type).value:"",
      problem_type:formValues?.problem_type?JSON.parse(formValues.problem_type).name:"",
      problem_type_id:formValues?.problem_type?JSON.parse(formValues.problem_type).value:"",
    };

    // Set specific properties based on the form name
    if (data?.form_name == "test_drive_form") {
      formData.next_followup_stage = "Test Drive";
      formData.next_followup_stage_id = "16637385418314";
    } else if (
      data?.form_name == formNames.PERSONAL_IDENTIFICATION_INFORMATION
    ) {
      formData.lead_source = "Web";
      formData.sub_source = "Configurator";
    }

    // Remove unnecessary properties from the formData
    delete formData["p_model"];
    delete formData["p_date"];
    delete formData["p_time"];
    delete formData["name"];
    delete formData["2w_dealer"];
    delete formData["2w_model"];

    // Set loading state to true
    setLoading(true);
    try {
      if(configuration.TRIGGER_SAVE_DATA_API_IN_BROCHURE_FORM === "true" || configuration.TRIGGER_SAVE_DATA_API_IN_BROCHURE_FORM === true){
        // Send a POST request to submit the form data
        formData=formNameCountry(formData)
        const res = await formApiHandler(formData);
        // Check the response to determine the action to take
        if (res?.message == "success") {
          // Handle success based on the form name
          if (data?.form_name == "finance_form" && data?.redirect) {
            window.open(
              data?.redirect_url +
                "?model=" +
                (router.query.brand ||
                  JSON.parse(formValues.model_code).model_name),
              "_blank"
            );
            const path = new URL(data?.redirect_url).pathname;
            const baseUrl = new URL(data?.redirect_url).origin;
            // if(path){
            //   router.push(path+"?model=" +
            //   (router.query.brand ||
            //     JSON.parse(formValues.model_code).model_name))
            // }
           
          }
          else if (data?.form_name == "brochure_form") {
        const now = new Date();
        const formattedTime = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
        if(brochureType === "accessory"){
          window.open(
            `${configuration.IMG_URL}${JSON.parse(formValues.model_code).accessorybrochure}`,
            "_blank"
          );
          }
        else{
        window.open(
          `${configuration.IMG_URL}${JSON.parse(formValues.model_code).brochure}`,
          "_blank"
        );
        }
            // const path = new URL(data?.redirect_url).pathname;
            // const baseUrl = new URL(data?.redirect_url).origin;
            // if(path){
            //   router.push(path)
  
            //  }
          } else if (data?.form_name == "brochure_form_2w") {
        window.open(
              configuration.IMG_URL +
                JSON.parse(formValues["2w_model"]).model_code +
                ".pdf",
              "_blank"
            );
            const path = new URL(data?.redirect_url).pathname;
            const baseUrl = new URL(data?.redirect_url).origin;
            if(path){
              router.push(path+"?redirected=true")
  
             }
          } 
           else {
            // Hash email and phone
            const hashedEmail = await hashInput(formData.Email);
            const hashedPhone = await hashInput(formData.Phone);
            // const path = new URL(data?.redirect_url);
            // const baseUrl = new URL(data?.redirect_url).origin;
            
            // Create a new URL object to manipulate the URL safely
            const url = new URL(data?.redirect_url);
            if (configuration.COUNTRY_CODE === "RO") {
            url.searchParams.set("email", hashedEmail);
            url.searchParams.set("phone", hashedPhone);
            }

              const path = url.toString(); // Convert URL object back to string
           if(path){
            router.push(path+"?redirected=true")
           

           }
            //The below code needs to be commented out if link need to be openned in new tab
            // data?.redirect && window.open(data?.redirect_url, "_blank",'noopener,noreferrer');
            // if(data?.redirect){
            //   window.location.href =data?.redirect_url;

            // }
          }
          //it is for car configurator form should be replaced with pii form
          if (
            data?.form_name == formNames.PERSONAL_IDENTIFICATION_INFORMATION
          ) {
            dispatch({ type: CAR_CONFIGURATOR_SUBMIT, payload: true });
            dispatch({
              type: PERSONAL_IDENTIFIALBLE_FORM_RES,
              payload: res?.responseData,
            });
          }
          //ends
          if (data?.form_name == formNames.SEND_TO_DEALER) {
            const res = await send_to_dealer_mail_configurator({
              configurator_data:
                car_configurator_data?.send_to_dealer_mail_data,
              email: formData?.Email,
            });
            toast.success(
              DYNAMIC_FORM_CONSTANT.FORM_SUBMITTED_SUCCESSFULLY_EXECUTIVE_WILL_REACH_YOU_SOON
            );
            return;
          }
          toast.success(DYNAMIC_FORM_CONSTANT.SUBMITTED);
      } else {
          // Display an error toast message
          toast.error(DYNAMIC_FORM_CONSTANT.SUBMIT_ERROR);
          dispatch({ type: PERSONAL_IDENTIFIALBLE_FORM_RES, payload: {} });
        }
      }
      else if(data?.form_name !== "brochure_form" && data?.form_name !== "brochure_form_2w"){
        // Send a POST request to submit the form data
        formData=formNameCountry(formData)
        const res = await formApiHandler(formData);
        // Check the response to determine the action to take
        if (res?.message == "success") {
          // Handle success based on the form name
          if (data?.form_name == "finance_form" && data?.redirect) {
            window.open(
              data?.redirect_url +
                "?model=" +
                (router.query.brand ||
                  JSON.parse(formValues.model_code).model_name),
              "_blank"
            );
            const path = new URL(data?.redirect_url).pathname;
            const baseUrl = new URL(data?.redirect_url).origin;
           
          }
           // Hash email and phone
           const hashedEmail = await hashInput(formData.Email);
           const hashedPhone = await hashInput(formData.Phone);
           // const path = new URL(data?.redirect_url);
           // const baseUrl = new URL(data?.redirect_url).origin;
           
           // Create a new URL object to manipulate the URL safely
           const url = new URL(data?.redirect_url);
           if (configuration.COUNTRY_CODE === "RO") {
            url.searchParams.set("email", hashedEmail);
            url.searchParams.set("phone", hashedPhone);
        }
        
             const path = url.toString(); // Convert URL object back to string
           if(path){
            router.push(path+"?redirected=true")

           }
          if (
            data?.form_name == formNames.PERSONAL_IDENTIFICATION_INFORMATION
          ) {
            dispatch({ type: CAR_CONFIGURATOR_SUBMIT, payload: true });
            dispatch({
              type: PERSONAL_IDENTIFIALBLE_FORM_RES,
              payload: res?.responseData,
            });
          }
          //ends
          if (data?.form_name == formNames.SEND_TO_DEALER) {
            const res = await send_to_dealer_mail_configurator({
              configurator_data:
                car_configurator_data?.send_to_dealer_mail_data,
              email: formData?.Email,
            });
            toast.success(
              DYNAMIC_FORM_CONSTANT.FORM_SUBMITTED_SUCCESSFULLY_EXECUTIVE_WILL_REACH_YOU_SOON
            );
            return;
          }
          toast.success(DYNAMIC_FORM_CONSTANT.SUBMITTED);
        } else {
          // Display an error toast message
          toast.error(DYNAMIC_FORM_CONSTANT.SUBMIT_ERROR);
          dispatch({ type: PERSONAL_IDENTIFIALBLE_FORM_RES, payload: {} });
        }
      
    }
      else{
        if (data?.form_name == "brochure_form") {
        const now = new Date();
        const formattedTime = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
         if(brochureType === "accessory"){
          window.open(
            `${configuration.IMG_URL}${JSON.parse(formValues.model_code).accessorybrochure}`,
            "_blank"
          );
        }
        else{
          window.open(
            `${configuration.IMG_URL}${JSON.parse(formValues.model_code).brochure}`,
            "_blank"
          );
        }
        } else if (data?.form_name == "brochure_form_2w") {
          window.open(
            configuration.IMG_URL +
              JSON.parse(formValues["2w_model"]).model_code +
              ".pdf",
            "_blank"
          );
        }
      }
     
    } catch (e) {
      dispatch({ type: PERSONAL_IDENTIFIALBLE_FORM_RES, payload: {} });
    } finally {
      // Set loading state to false, reset the form, and close the dialog
      setLoading(false);
      reset();
      onClose();
      setIsFormSubmitted(false)

    }
  };


  const handleSelectChange = async (event) => {
    const selectId = event.target.id; // Get the ID of the select input
    await trigger(selectId); // Trigger validation on the select input
  };

  let consentKeys = allarray.filter((key) => key.includes("_consent"));
  let MarketingconsentKeys = allMarketingarray.filter((key) =>
    key.includes("marketing_")
  );

  useEffect(() => {
    // for set the marketing_consent_via_distributor value in submit data
    if (first) {
      setValue("marketing_consent_via_distributor", true);
    } else {
      setValue("marketing_consent_via_distributor", false);
    }
    // for set the marketing_consent_via_distributor value in submit data
    if (second) {
      setValue("marketing_consent_via_dealer", true);
    } else {
      setValue("marketing_consent_via_dealer", false);
    }
    // for set the marketing_consent_via_distributor value in submit data

    if (vault) {
      setValue("direct_marketing_consent", true);
    } else {
      setValue("direct_marketing_consent", false);
    }
  }, [first, second, vault]);

  const handlePDFDownload = () => {
    // URL of the PDF file
    const pdfUrl = process.env.NEXT_PUBLIC_PRIVACY_POLICY_PDF
    window.open(pdfUrl, "_blank");
  };

  const updateDealerDetail = (edata) => {
    try {
      // Parse the incoming JSON string
      const parsedData = typeof edata === "string" ? JSON.parse(edata) : edata;
      const { dealerName, dealerCode, value } = parsedData;
      // console.log("Dealer Name:", dealerName); // Should log: SC IRMEX SA
      // console.log("Dealer Code:", dealerCode); // Should log: HUA253810
       setdealerDetail(
        ' ( ' + 
        dealerName +
        (configuration.SHOW_DEALER_ADDRESS.LEVEL_1 === "true" && value?.addressLevel1?.name
           ? ` - ${ value?.addressLevel1?.name }`
          : "") +

          (configuration.SHOW_DEALER_ADDRESS.LEVEL_2 === "true" && value?.addressLevel2?.name
            ? ` - ${ value?.addressLevel2?.name }`
           : "") +

           (configuration.SHOW_DEALER_ADDRESS.LEVEL_3 === "true" && value?.addressLevel3?.name
            ? ` - ${ value?.addressLevel3?.name }`
           : "") +

           (configuration.SHOW_DEALER_ADDRESS.LEVEL_4 === "true" && value?.addressLevel4?.name
            ? ` - ${ value?.addressLevel1?.name }`
           : "") +


          (configuration.SHOW_DEALER_ADDRESS.LEVEL_5 === "true" && value?.addressLevel5?.name
            ? ` - ${ value?.addressLevel1?.name }`
           : "") +         

        ' )'  
        );
    } catch (error) {
      console.error("Error parsing data:", error);
    }
  };

  function renderMarketingInput(item) {
    switch (item?.field_name) {
   case "combined_consent":
      return (
        <>
         {(process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS=="true" || process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS==true)  && <p className="font-suzukiBold font-default  pt-[13px]">
              {DYNAMIC_FORM_CONSTANT.Marketing_By_Suzuki}
            </p>}
       <div className={`${process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS=="true" || process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS==true ? "flex flex-wrap" : "flex-col"}`}>

          {suzukiApproval &&
                  suzukiApproval?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`pb-[10px] flex relative ${
                          item?.field_name == "outletId" && "col-span-2"
                        } ${item?.field_name == "terms" && "col-span-2"} ${
                          item?.field_name == "service_model" &&
                          showOtherModelInput &&
                          "col-span-2 flex" 
                        } ${
                          item?.input_type == "checkbox" &&
                          item?.field_name.includes("_consent") &&
                          "col-span-2 pb-0 form-consent-field"
                        }`}
                      >
                        {/* Render individual input fields  of marketing section*/}
                        <fieldset disabled={loading}>
                        {(() => {
          switch (item?.field_name) {  // Assuming `sub_field_name` is the property for the nested switch
      case "marketing_consent_via_distributor":
        return (
          <>
           {(process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS=="false" || process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS==false)  && <p className="font-suzukiBold font-default  pt-[13px]">
              {DYNAMIC_FORM_CONSTANT.Marketing_By_Suzuki}
            </p>}
            <div className="flex justify-start mt-0  ">
              <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
                <input
                id="marketingConsentViaDistributorField"
                  className="w-[18px] h-[21px]"
                  type="checkbox"
                  // checked ={first}
                  // onClick={()=>callfun1(item)}
                  {...register(item.field_name)}
                />
              </span>

              <div className="relative w-full mr-[15px]">
                <span className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default marginclass"  dangerouslySetInnerHTML={{ __html: item?.display_name }}/>
              </div>
            </div>
          </>
        );
      case "marketing_consent_via_dealer":
        return (
          <>
              {(process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS=="false" || process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS==false)  && <p className="font-suzukiBold font-default   pt-[13px]">
              {DYNAMIC_FORM_CONSTANT.Marketing_By_Suzuki_Dealers}
              </p>}
            <div className="flex  justify-start mt-0  ">
              <span className="w-[18px] h-[21px] mr-[8px] flex ">
                <input
                    id="marketingConsentViaDealerField"
                  className="w-[18px] h-[21px]"
                  type="checkbox"
                  //  checked ={second}
                  //  onClick={()=>callfun2(item)}
                  {...register(item.field_name)}
                />
              </span>
                <div className="relative w-full">
  <span
    className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default"
    dangerouslySetInnerHTML={{ __html: item?.display_name }}
  />
  
  <span className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default">
    {/* {dealerDetail} */}
    {dealerDetail && dealerDetail != "(undefined)" ?dealerDetail:reduxData?.selected_dealer?.name && (reduxData?.selected_dealer?.name !="undefined" || reduxData?.selected_dealer?.name != undefined) ?`(${reduxData?.selected_dealer?.name})`:""} 
                </span>
              </div>

            </div>
          </>
        );
            default:
              return (
                <InputField
                label={item?.display_name}
                type={item?.field_name === "Phone" ? "number" : item?.field_name}
                required={!(item?.mandatory === "false" || !item?.mandatory)}
                {...register(item?.field_name, {
                  required: !(item?.mandatory === "false" || !item?.mandatory)
                    ? DYNAMIC_FORM_CONSTANT.REQUIRED_FIELD
                    : false,
                })}
                placeholder={item?.display_name}
                error={errors[item?.field_name]?.message}
                showTooltip={item?.info && item?.info_text}
                iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
                descript={item?.info_text}
              />
                                );
                              }
                            })()}
                        </fieldset>
                      </div>
                    );
                  })} 
            </div>
        </>
      )

      case "marketing_channels":
      return (
        <>
          <p className="font-suzukiBold font-default   pt-[13px]">
              {DYNAMIC_FORM_CONSTANT.MARKETING_CHANNELS}
            </p>
            <p>{DYNAMIC_FORM_CONSTANT.AGREE_CONSENT}</p>
        <div className={`${process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS=="true" || process.env.NEXT_PUBLIC_HORIZONTAL_CHECKS==true ? "flex flex-wrap" : "flex-col"}`}>
      
             {/* Check if there are marketing consent keys to Select  the all named checkbox*/}
            {marketing_channels?.length > 1 && (
              <div className="selectAll-box flex items-center mb-[15px] mr-[17px]">
                <span className="w-[18px] h-[21px] mr-[8px]">
                  <input
                    className="w-[18px] h-[21px]"
                    id="AllCheckbox"
                    type="checkbox"
                    checked={checkboxDisable ? null : All}
                    onChange={(e) => {
                      // Select all named marketingconsent keys
                      [...MarketingconsentKeys].forEach((item) => {
                        setValue(item, e.target.checked);
                      });

                      setAll(true);
                      trigger([...MarketingconsentKeys]);
                    }}
                  />
                </span>
                <div>
                  <label
                    className="relative text-[14px]   self-center text-defaut-color font-suzukiRegular text-default"
                    htmlFor="AllCheckbox"
                  >
                   {marketing_channels?.length >2 ? DYNAMIC_FORM_CONSTANT.ALL : DYNAMIC_FORM_CONSTANT.BOTH}
                  </label>
                </div>
                {/* {( (second||first) && ischecked )  ?<p className="text-[#FF0000] text-[12px]  ml-10">{DYNAMIC_FORM_CONSTANT.MARKETING_CONSENTS_WARNING}</p>:null} */}
              </div>
            )}
          {marketing_channels &&
                  marketing_channels?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`pb-[20px] flex relative ${
                          item?.field_name == "outletId" && "col-span-2"
                        } ${item?.field_name == "terms" && "col-span-2"} ${
                          item?.field_name == "service_model" &&
                          showOtherModelInput &&
                          "col-span-2 flex" 
                        } ${
                          item?.input_type == "checkbox" &&
                          item?.field_name.includes("_consent") &&
                          "col-span-2 pb-0 form-consent-field"
                        }`}
                      >
                        {/* Render individual input fields  of marketing section*/}
                        <fieldset disabled={loading}>
                        {(() => {
          switch (item?.field_name) {  // Assuming `sub_field_name` is the property for the nested switch
      case "marketing_email_consent":
      case "marketing_sms_consent":
                case "marketing_phone_consent":
        return (
          <>
                    <div className="flex justify-start mt-0 items-center">
              <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
                <input
                          id={item?.field_name === "marketing_email_consent" ? "marketingEmailConsentField" :
                          item?.field_name === "marketing_sms_consent" ? "marketingSmsConsentField" :
                          item?.field_name === "marketing_phone_consent" ? "marketingPhoneConsentField" :
                          "defaultConsentField"
                        }
                  className="w-[18px] h-[21px]"
                  type="checkbox"
                  {...register(item.field_name)}
                />
              </span>
              <div className="relative w-full mr-[15px]">
                        <span className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default marginclass"  dangerouslySetInnerHTML={{ __html: item?.display_name }}/>
                          
                       
              </div>
            </div>
          </>
        );
            default:
              return (
                <InputField
            label={item?.display_name}
            type={item?.field_name === "Phone" ? "number" : item?.field_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.REQUIRED_FIELD
                : false,
            })}
            placeholder={item?.display_name}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
              );
          }
        })()}
                        </fieldset>
                      </div>
                    );
                  })} 
       </div>
        </>
      ) 


      case "contact_consent":
        return (
          <>
            {/* {render the info box } */}
            <p className="font-suzukiBold font-default  pt-[13px]">
                {DYNAMIC_FORM_CONSTANT.Consent_Information}
              </p>
            <div className="border-[1px] border-[#C0C0C0]  h-[100px] overflow-y-auto p-2  ">
              {data.form_name === "contact_form" ? (
                <div>
                  <p>{DYNAMIC_FORM_CONSTANT.CONTACT_US_INFO.FIRST_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.CONTACT_US_INFO.SECOND_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.CONTACT_US_INFO.THIRD_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.CONTACT_US_INFO.FOURTH_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.CONTACT_US_INFO.FIFTH_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.CONTACT_US_INFO.SIXTH_POINT}</p>
                </div>
              ) : (
                <div>
                  <p>{DYNAMIC_FORM_CONSTANT.ALL_FORM_INFO.FIRST_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.ALL_FORM_INFO.SECOND_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.ALL_FORM_INFO.THIRD_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.ALL_FORM_INFO.FOURTH_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.ALL_FORM_INFO.FIFTH_POINT}</p>
                  <p>{DYNAMIC_FORM_CONSTANT.ALL_FORM_INFO.SIXTH_POINT}</p>
                </div>
              )}
            </div>
            <div>
              <p className="font-suzukiBold font-verySmall   pt-[13px]">
                {DYNAMIC_FORM_CONSTANT.Info_About_Button}
              </p>
              <Button
                type="button"
                className={`text-inverted uppercase border-[1px] border-[#001151] w-[171px] h-[40px] rounded-[90px]  bg-[#001151] text-[13px] ${
                  loading && "opacity-50"
                }
                `}
                onClick={handlePDFDownload}
              >
                {DYNAMIC_FORM_CONSTANT.View_Privacy_Notice}
              </Button>
            </div>
          
          </>
        );
    }
  }

  function renderInput(item) {
    switch (item?.field_name) {
      case "brochure_type":
        return(
          <SelectInput
            id={item?.field_name}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={ selectedmodel?.accessorybrochure ?  [{name : DYNAMIC_FORM_CONSTANT.BROCHURE_OPTION1 , value : "model"}, {name : DYNAMIC_FORM_CONSTANT.BROCHURE_OPTION2 , value : "accessory"}] : [{name : DYNAMIC_FORM_CONSTANT.BROCHURE_OPTION1 , value : "model"}]}
            // items={[{name : DYNAMIC_FORM_CONSTANT.BROCHURE_OPTION1 , value : "model"}, {name : DYNAMIC_FORM_CONSTANT.BROCHURE_OPTION2 , value : "accessory"}]}
            {... {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                : false,
                onChange: (e) => {
                  setbrochureType(JSON.parse( e.target.value).value)
                },
            }}
            error={errors[item?.field_name]?.message}
            placeholder={DYNAMIC_FORM_CONSTANT.BROCHURE_SELECT}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
          )
      case "direct_marketing_consent":
        return (
          <div className="">
            <div>
              {/* <p className="font-suzukiBold font-default pt-[13px] ">{DYNAMIC_FORM_CONSTANT.OPTIONAL_CONSENT}</p>   */}
              <TitleText>{DYNAMIC_FORM_CONSTANT.OPTIONAL_CONSENT}</TitleText>
              <div>
                {marketingFields &&
                  marketingFields?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`relative ${
                          item?.field_name == "outletId" && "col-span-2"
                        } ${item?.field_name == "terms" && "col-span-2"} ${
                          item?.field_name == "service_model" &&
                          showOtherModelInput &&
                          "col-span-2"
                        } ${
                          item?.input_type == "checkbox" &&
                          item?.field_name.includes("_consent") &&
                          "col-span-2 pb-0 form-consent-field"
                        }`}
                      >
                        {/* Render individual input fields  of marketing section*/}
                        <fieldset disabled={loading}>
                          {renderMarketingInput(item)}
                        </fieldset>
                      </div>
                    );
                  })}
                <div>
                  <p className="font-suzukiBold font-default pt-[13px]">
                    {DYNAMIC_FORM_CONSTANT.Right_To_Withdraw_Consent}
                  </p>

                  <p className="mb-[1rem]">
                    {DYNAMIC_FORM_CONSTANT.WithDraw_Consent_Info_1}{" "}
                    <a
                      href={
                        process.env.NEXT_PUBLIC_DOMAIN_NAME + "/update-consent"
                      }
                      target="_blank"
                      className="display_link"
                    >
                    {DYNAMIC_FORM_CONSTANT.Link}
                    </a>{" "}
                    {DYNAMIC_FORM_CONSTANT.WithDraw_Consent_Info_2}{" "}
                    {DYNAMIC_FORM_CONSTANT.WithDraw_Consent_Info_3}{" "}
                   
                    <a href="#" className="display_link">
                     {DYNAMIC_FORM_CONSTANT.ProtectionEmail}
                    </a>{" "}
                    {DYNAMIC_FORM_CONSTANT.WithDraw_Consent_Info_4}
                  </p>
                </div>
              </div>
              {(process.env.NEXT_PUBLIC_SHOW_MANDATORY_CONSENT_TITLE=="true"|| process.env.NEXT_PUBLIC_SHOW_MANDATORY_CONSENT_TITLE ==true) && <TitleText>{DYNAMIC_FORM_CONSTANT.Mandatory_Consents}</TitleText>}
            </div>
          </div>
        );

      case "service_model":
        return (
          <div
            className={showOtherModelInput && `grid grid-cols-2 gap-x-[25px]`}
          >
            <div>
              <SelectInput
                id={item?.field_name}
                label={item?.display_name}
                required={!(item?.mandatory === "false" || !item?.mandatory)}
                items={modelData?.filter(item => item?.show_in_dropdown === true)}
                placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_MODEL}
                // disabled={modelDisabled}
                {...register(item?.field_name, {
                  required: !(item?.mandatory === "false" || !item?.mandatory)
                    ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                    : false,
                  onChange: (e) => {
                    if (JSON.parse(e?.target?.value)?.name === "OTHERS") {
                      setShowOtherModelInput(true);
                    } else {
                      setShowOtherModelInput(false);
                    }
                  },
                })}
                error={errors[item?.field_name]?.message}
                showTooltip={item?.info && item?.info_text}
                iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
                descript={item?.info_text}
              />
            </div>
            <div>
              {showOtherModelInput && (
                <InputField
                  label={Constants.forms.OTHER_FIELD}
                  placeholder="Enter service model name"
                  {...register("otherModelName", {
                    required: Constants.forms.REQUIRED_FIELD,
                  })}
                  error={errors["otherModelName"]?.message}
                />
              )}
            </div>
          </div>
        );
      case "p_car ":
      case "model_code":
        return (
          <SelectInput
            id={item?.field_name}
            modelCodePage={modelCodePage}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={finalModel[item.form_name]?.filter(item =>item?.show_in_dropdown===undefined || item?.show_in_dropdown === true)}
            placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_MODEL}
            // disabled={modelDisabled}
            {...register(item?.field_name, {
              onChange: (e) => {
                setVariants(JSON.parse(e.target.value).variants)
                setselectedmodel(JSON.parse(e.target.value))
              },
              required:
                !(item?.mandatory === "false" || !item?.mandatory) &&
                router.query.brand == undefined
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
                  validate: (value) => {
                    // Check if the field is required and validate accordingly
                    const isRequired = !(item?.mandatory === "false" || !item?.mandatory);
                    if (isRequired && (value === "" || value === undefined)) {
                      return DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE;
                    }
                    return true;
                  },
            })}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "2w_model":
        return (
          <SelectInput
            id={item?.field_name}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={twoWheelerData}
            placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_MODEL}
            disabled={modelDisabled}
            {...register(item?.field_name, {
              onChange: (e) => setVariants(JSON.parse(e.target.value).variants),
              required:
                !(item?.mandatory === "false" || !item?.mandatory) &&
                router.query.brand == undefined
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
            })}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "down_payment":
        return (
          <SelectInput
            id={item?.field_name}
            handleChange={handleSelectChange}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={downPaymentOptions}
            placeholder={DYNAMIC_FORM_CONSTANT.DOWN_PAYMENT}
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                : false,
            })}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "variant_code":
        return (
          <SelectInput
            id={item?.field_name}
            handleChange={handleSelectChange}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={variants}
            disabled={variantDisabled}
            placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_VARIANT}
            nameKey="variant_name"
            valueKey="variant_code"
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                : false,
            })}
            error={errors[item?.field_name]?.message}
          showTooltip={item?.info && item?.info_text}
          iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
          descript={item?.info_text}
          />
        );
      case "communication_mode":
        return (
          <SelectInput
            id={item?.field_name}
            handleChange={handleSelectChange}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={communicationOptions}
            placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_COMMUNICATION_MODE}
            defaultValue={communicationOptions[0]?.value}
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                : false,
            })}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "finance_option":
        return (
          <SelectInput
            id={item?.field_name}
            handleChange={handleSelectChange}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={selectFinanceOptions}
            placeholder={DYNAMIC_FORM_CONSTANT.SELECT_FINANCING_OPTION}
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                : false,
            })}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "purchase_type":
        return (
          <SelectInput
            id={item?.field_name}
            handleChange={handleSelectChange}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={purchaseOptions}
            placeholder={DYNAMIC_FORM_CONSTANT.PURCHASE_TYPE}
            defaultValue={purchaseOptions[0]?.value} 
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                : false,
            })}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
       
      case "planning_to_purchase":
        return (
          <SelectInput
            id={item?.field_name}
            handleChange={handleSelectChange}
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            items={planningToPurchaseOptions}
            defaultValue={planningToPurchaseOptions[0]?.name}
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                : false,
            })}
            error={errors[item?.field_name]?.message}
            placeholder={DYNAMIC_FORM_CONSTANT.PLANNING_TO_PURCHASE_IN}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "outletId":
      case "p_dealership":
        return (
          <div className="grid gap-[25px] md:grid-cols-2 lg:grid-cols-2">
            <LocationSearchInput
              lob={LobValue=="1"?configuration.COMMON_MASTER_LOB:LobValue=="2"?configuration.COMMON_MASTER_LOB_2W:LobValue=="3"?configuration.COMMON_MASTER_LOB_MARINE:"1"}
              onUpdate={(e) => {
                setDealerData(e);
              }}
            />
            {/* <SelectInput
              id={item?.field_name}
              handleChange={handleSelectChange}
              label={item?.display_name}
              // required={!(item?.mandatory === "false" || !item?.mandatory)}
              items={dealerData}
              {...register(item?.field_name, {
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
              })}
              error={errors[item?.field_name]?.message}
              placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_DEALERSHIP}
              value={reduxData.selected_dealer}
            /> */}
            <Controller
              name={item?.field_name}
              control={control}
              defaultValue=""
              rules={{
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
              }}
              
              render={({ field: { value, onChange, onBlur, name } }) => (
                <>
                  {configuration?.COUNTRY_CODE=="VNM" && (configuration.DEALER_REGIONAL_DROPDOWN_GROUPING === "true") && !isLocationOn ? 
                    <RegionalDropdown
                      id={item?.field_name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      handleSelectChange={handleSelectChange}
                      onBlur={onBlur}
                      required={!(item?.mandatory === "false" || !item?.mandatory)}
                      placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_DEALERSHIP}
                      options={configuration?.COUNTRY_CODE=="PH" && path =="/pages/get-a-quote-form"?dealerData.sort((a,b)=>{return a.name.localeCompare(b.name)}):dealerData}
                      label={item?.display_name}
                      error={errors[item?.field_name]?.message}
                      isSubmitting={isSubmitting}
                      setdealerconsentadress={setdealerconsentadress}
                    />
                  :
                    <CustomDropdown
                      id={item?.field_name}
                      name={name}
                      value={value}
                      onChange={onChange}
                      handleSelectChange={handleSelectChange}
                      onBlur={onBlur}
                      required={!(item?.mandatory === "false" || !item?.mandatory)}
                      placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_DEALERSHIP}
                      options={configuration?.COUNTRY_CODE=="PH" && path =="/pages/get-a-quote-form"?dealerData.sort((a,b)=>{return a.name.localeCompare(b.name)}):dealerData}
                      label={item?.display_name}
                      error={errors[item?.field_name]?.message}
                      isSubmitting={isSubmitting}
                      setdealerconsentadress={setdealerconsentadress}
                    />
                  }
                </>
              )}
            />
          </div>
        );
      case "2w_dealer":
        return (
          <div className="grid gap-[25px] md:grid-cols-2 lg:grid-cols-2">
            <LocationSearchInput
            id="2wDealerField"
              lob={configuration.COMMON_MASTER_LOB_2W}
              onUpdate={(e) => {
                setDealers2w(e);
              }}
            />
            {/* <SelectInput
              id={item?.field_name}
              handleChange={handleSelectChange}
              label={item?.display_name}
              // required={!(item?.mandatory === "false" || !item?.mandatory)}
              items={dealers2w}
              {...register(item?.field_name, {
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
              })}
              error={errors[item?.field_name]?.message}
              placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_DEALERSHIP}
              value={reduxData.selected_dealer}
            /> */}
            <Controller
              name={item?.field_name}
              control={control}
              defaultValue=""
              rules={{
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
              }}
              render={({ field: { value, onChange, onBlur, name } }) => (
                <CustomDropdown
                  id={item?.field_name}
                  name={name}
                  value={value}
                  onChange={onChange}
                  handleSelectChange={handleSelectChange}
                  required={!(item?.mandatory === "false" || !item?.mandatory)}
                  onBlur={onBlur}
                  placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_DEALERSHIP}
                  options={dealers2w}
                  label={item?.display_name}
                  error={errors[item?.field_name]?.message}
                  isSubmitting={isSubmitting}
                  setdealerconsentadress={setdealerconsentadress}
                />
              )}
            />
          </div>
        );
      case "p_time":
        return (
          <SelectInput
            id={item?.field_name}
            handleChange={handleSelectChange}
            label={item?.display_name}
            disabled={!values.p_date}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            placeholder={DYNAMIC_FORM_CONSTANT.PREFFERED_TIME}
            items={isToday ? currentTimeSlots : timeSlots}
            defaultValue={timeSlots[0].value}
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                : false,
            })}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "p_date":
        return (
          <InputField
          id="pDateField"
            label={item?.display_name}
            min={moment(new Date()).format("YYYY-MM-DD")}
            type="date"
            placeholder={
              isSubmitting
                ? moment(values.p_date).format("MM/DD/YYYY")
                : item?.display_name
            }
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            handleChange={(date) => {
              field.onChange(date);
              if (
                new Date(date).toDateString() == new Date(today).toDateString()
              ) {
                setIsToday(true);
              } else {
                setIsToday(false);
              }
              resetField("p_time");
            }}
            error={
              errors[
                item?.field_name === "name" ? "first_name" : item?.field_name
              ]?.message
            }
            {...register(
              item?.field_name === "name" ? "first_name" : item?.field_name,
              {
                onChange: (e) => {
                  if (
                    new Date(e.target.value).toDateString() ==
                    new Date(today).toDateString()
                  ) {
                    setIsToday(true);
                  } else {
                    setIsToday(false);
                  }
                  resetField("p_time");
                },
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.REQUIRED_FIELD
                  : false,
              }
            )}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            srcIcon={`${configuration.IMG_STATIC_URL}assets/images/calenderIcon.svg`}
            showIcons={true}
            descript={item?.info_text}
          />
        );
        case "data_privacy_consent":
          case "data_privacy_notice_consent":
          case "phone_consent":
          case "email_consent":
          case "terms":
          case "call_centre_consent":
      
            return (
              <>
                <div className="flex justify-start mt-0">
                  <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
                    <input
                      id={
                  item?.field_name === "data_privacy_consent" ? "dataPrivacyConsentField" :
                  item?.field_name === "data_privacy_notice_consent" ? "dataPrivacyNoticeConsentField" :
                  item?.field_name === "phone_consent" ? "phoneConsentField" :
                  item?.field_name === "email_consent" ? "emailConsentField" :
                  item?.field_name === "terms" ? "termsField" :
                  item?.field_name === "call_centre_consent" ? "callCentreConsentField" :
                  "defaultConsentField"
                      }
                      className="w-[18px] h-[21px]"
                      type="checkbox"
                      {...register(item?.field_name, {
                        required: !(item?.mandatory === "false" || !item?.mandatory)
                          ? DYNAMIC_FORM_CONSTANT.CLICK_TO_PROCEED
                          : false,
                      })}
                    />
                  </span>
                  <div className="relative w-full">
                <span className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default marginclass">
                  {/* Conditionally render the terms link */}
                  {item.field_name === "terms" && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.NEXT_PUBLIC_PRIVACY_POLICY_PDF}
                    className=" font-suzukiBold underline text-default text-primary-blue"
                  >
                    {DYNAMIC_FORM_CONSTANT.PRIVACY_POLICY}
                  </a>
                  )}
                  &nbsp; &nbsp;
                  <p className="inline-block mb-0" dangerouslySetInnerHTML={{ __html: item?.display_name }} />
                </span>
                {errors[item?.field_name] && (
                  <p className="text-[#FF0000] text-[12px] mb-0">
                    {errors[item?.field_name]?.message}
                  </p>
                )}
              </div>
                </div>
              </>
            );
      case "dealer_consent":
        return (
          <div className="flex justify-start mt-0">
            <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
              <input
              id="dealerConsentField"
                className="w-[18px] h-[21px]"
                type="checkbox"
                {...register("share_personal_details_with_dealer", {
                  required: !(item?.mandatory === "false" || !item?.mandatory)
                    ? DYNAMIC_FORM_CONSTANT.CLICK_TO_PROCEED
                    : false,
                })}
              />
            </span>
            <div className="relative w-full">
  <span
    className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default"
    dangerouslySetInnerHTML={{ __html: item?.display_name }}
  />
  
              <span className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default">
    {/* {dealerDetail} */}
    {dealerDetail && dealerDetail != "(undefined)" ?dealerDetail:reduxData?.selected_dealer?.name && (reduxData?.selected_dealer?.name !="undefined" || reduxData?.selected_dealer?.name != undefined) ?`(${reduxData?.selected_dealer?.name})`:""} 
              </span>

              {errors[item?.field_name] && (
                <p className="text-[#FF0000] text-[12px] mb-0">
                  {errors[item?.field_name]?.message}
                </p>
              )}
            </div>

          </div>
        );

      case "privacy_policy_consent":
      case "tnc_consent":
        return (
          <>
            <div className="flex justify-start">
              <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
                <input
                id="privacyPolicyConsentField"
                  className="w-[18px] h-[21px]"
                  type="checkbox"
                  {...register(item?.field_name, {
                    required: !(item?.mandatory === "false" || !item?.mandatory)
                      ? DYNAMIC_FORM_CONSTANT.CLICK_TO_PROCEED
                      : false,
                  })}
                />
              </span>
              <div className="relative w-full">
              <span className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default marginclass" dangerouslySetInnerHTML={{ __html: item?.display_name }}/>
                {errors[item?.field_name] && (
                  <p className="text-[#FF0000] text-[12px] mb-0">
                    {errors[item?.field_name]?.message}
                  </p>
                )}
              </div>
            </div>
          </>
        );
        
        // case "tnc_consent":
        //   return (
        //     <>
        //       <div className="flex justify-start">
        //         <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
        //           <input id="tncConsentField"
        //             className="w-[18px] h-[21px]"
        //             type="checkbox"
        //             {...register(item?.field_name, {
        //               required: !(item?.mandatory === "false" || !item?.mandatory)
        //                 ? DYNAMIC_FORM_CONSTANT.CLICK_TO_PROCEED
        //                 : false,
        //             })}
        //           />
        //         </span>
        //         <div className="relative w-full">
        //           <span className="self-center text-default text-defaut-color">
        //             <a
        //               target="_blank"
        //               rel="noopener noreferrer"
        //               href={process.env.NEXT_PUBLIC_TERMS_AND_CONDITION_LINK}
        //               className=" font-suzukiBold underline text-default text-primary-blue"
        //             >
        //               {DYNAMIC_FORM_CONSTANT.TERMS_AND_CONDITIONS}
        //             </a>
        //             &nbsp; &nbsp;
        //             <p className="inline-block mb-0">{item?.display_name}</p>
        //           </span>
        //           {errors[item?.field_name] && (
        //             <p className="text-[#FF0000] text-[12px] mb-0">
        //               {errors[item?.field_name]?.message}
        //             </p>
        //           )}
        //         </div>
        //       </div>
        //     </>
        //   );
      case "Phone":
        return (
          <InputField
          id="phoneField"
            label={item?.display_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            error={configuration.COUNTRY_CODE!="IDN" && errors[item?.field_name]?.message}
            {...register(item?.field_name, {
              ...PhoneValidation,
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.REQUIRED_FIELD
                : false,
            })}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
            type="tel"
            pattern={
              configuration?.COUNTRY_CODE === "IDN"
                ? undefined
                : configuration?.COUNTRY_CODE === "HUN"
                ? "[0-9]{11}"
                : "[0-9]{10}"
            } // Regular expression to allow only 11-digit numbers

            onKeyPress={(event) => {
              let number=event.target.value;
              let stringWithoutSpecialChars = number.replace(/[^a-zA-Z0-9]/g, '');
              event.target.value=stringWithoutSpecialChars
              if (!/\d/.test(event.key)) {
                event.preventDefault();
              } 
               if (event.target.value.length > process.env.NEXT_PUBLIC_PHONE_NUMBER_MAX_LENGTH && configuration.COUNTRY_CODE!="IDN") {
                event.preventDefault();
              }

              if(configuration.COUNTRY_CODE=="HUN" && event.target.value.startsWith("06")){
                event.preventDefault();
              }
            }}
            // onPaste={(event)=>{
            //   let number=(event.clipboardData || window?.clipboardData).getData('text');
            //   let stringWithoutSpecialChars = number.replace(/[^a-zA-Z0-9]/g, '');
            //   // event.clipboardData.setData('text')=""
            //   event.target.value=stringWithoutSpecialChars
            //   event.preventDefault(); 

            // }}
            onPaste={(event) => {
              event.preventDefault(); // Prevent default paste behavior
            
              let pastedNumber = (event.clipboardData || window?.clipboardData).getData('text');
              let sanitizedNumber = pastedNumber.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            
              event.target.value = sanitizedNumber; // Set input value manually
              setValue(item?.field_name, sanitizedNumber);
              trigger(item?.field_name);
            }}
            
          />
        );
      case "Email":
        return (
          <InputField
          id="emailField"
            label={item?.display_name}
            type="email"
            min={tomorrowDate}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            error={errors[item?.field_name]?.message}
            {...register(item?.field_name, {
              ...EmailValidation,
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.REQUIRED_FIELD
                : false,
            })}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "first_name":
      case "last_name":
      case "name":
        return (
          <InputField
          id={
            item?.field_name === "name" ? "nameField" :
            item?.field_name === "first_name" ? "firstNameField" :
            item?.field_name === "last_name" ? "lastNameField" :
            "defaultNameField"
          }
            label={item?.display_name}
            type="text"
            min={tomorrowDate}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            error={
              errors[
                item?.field_name === "name" ? "first_name" : item?.field_name
              ]?.message
            }
            {...register(
                item?.field_name,
              {
                ...NameValidation,
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.REQUIRED_FIELD
                  : false,
              }
            )}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
      case "phone_or_email_consent":
        return (
          <PhoneOrInputConsent
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.REQUIRED_FIELD
                : false,
            })}
            checkAll={selectAll}
            changeHandler={(e) => {
              setValue("phone_or_consent", e.phone);
              setValue("email_or_consent", e.email);
              if (e.phone || e.email) {
                setValue(item?.field_name, true);
              } else {
                setValue(item?.field_name, false);
              }
              trigger(item?.field_name);
            }}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            label={item?.display_name}
            error={errors[item?.field_name]?.message}
          />
        );

        case "selected_dealer":
          return (
            <div className="grid gap-[25px] ">
              
           
<Controller
              name={item?.field_name}
              control={control}
              defaultValue=""
              rules={{
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
              }}
              render={({ field: { value, onChange, onBlur, name } }) => (
                <CustomDropdown
                  id={item?.field_name}
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange?.(e); // Call the first onChange handler, if defined
                    updateDealerDetail(e); // Call the second onChange handler
                  }}
                  handleSelectChange={handleSelectChange}
                  onBlur={onBlur}
                  placeholder={DYNAMIC_FORM_CONSTANT.SELECTED_DEALERS}
                  required={!(item?.mandatory === "false" || !item?.mandatory)}
                  options={item.selected_dealer}
                  label={item?.display_name}
                  error={errors[item?.field_name]?.message}
                  isSubmitting={isSubmitting}
                  setdealerconsentadress={setdealerconsentadress}
                />
              )}
            />

            
            </div>
            );
            // new field for hungary
        case "problem_type":
          return (
            // <SelectInput
            //   id={item?.field_name}
            //   handleChange={handleSelectChange}
            //   label={item?.display_name}
            //   required={!(item?.mandatory === "false" || !item?.mandatory)}
            //   items={inquiryTypes}
            //   placeholder={DYNAMIC_FORM_CONSTANT.SELECT_INQUIRY_TYPE?DYNAMIC_FORM_CONSTANT.SELECT_INQUIRY_TYPE:"Select Enquiry Types"}
            //   {...register(item?.field_name, {
            //     required: !(item?.mandatory === "false" || !item?.mandatory)
            //       ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
            //       : false,
            //   })}
            //   error={errors[item?.field_name]?.message}
            // />
            <Controller
              name={item?.field_name}
              control={control}
              defaultValue=""
              rules={{
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
              }}
              
              render={({ field: { value, onChange, onBlur, name } }) => (
                <CustomDropdown
                  id={item?.field_name}
                  name={name}
                  value={value}
                  placeholderName={DYNAMIC_FORM_CONSTANT.SELECT_INQUIRY_TYPE?DYNAMIC_FORM_CONSTANT.SELECT_INQUIRY_TYPE:"Select Enquiry Types"}
                  onChange={onChange}
                  handleSelectChange={handleSelectChange}
                  onBlur={onBlur}
                  required={!(item?.mandatory === "false" || !item?.mandatory)}
                  // options={configuration?.COUNTRY_CODE=="PH" && path =="/pages/get-a-quote-form"?dealerData.sort((a,b)=>{return a.name.localeCompare(b.name)}):dealerData}
                  options={inquiryTypes}
                  label={item?.display_name}
                  error={errors[item?.field_name]?.message}
                  isSubmitting={isSubmitting}
                  // setdealerconsentadress={setdealerconsentadress}
                />
              )}
            />
          );

          case "service_type":
            return (
              // <SelectInput
              //   id={item?.field_name}
              //   handleChange={handleSelectChange}
              //   label={item?.display_name}
              //   required={!(item?.mandatory === "false" || !item?.mandatory)}
              //   items={JSON.parse(ServicesTypes)}
              //   placeholder={DYNAMIC_FORM_CONSTANT.SELECT_SERVICES_TYPES?DYNAMIC_FORM_CONSTANT.SELECT_SERVICES_TYPES:"Select Services"}
              //   {...register(item?.field_name, {
              //     required: !(item?.mandatory === "false" || !item?.mandatory)
              //       ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
              //       : false,
              //   })}
              //   error={errors[item?.field_name]?.message}
              // />
              <Controller
              name={item?.field_name}
              control={control}
              defaultValue=""
              rules={{
                required: !(item?.mandatory === "false" || !item?.mandatory)
                  ? DYNAMIC_FORM_CONSTANT.SELECT_A_VALID_CHOICE
                  : false,
              }}
              
              render={({ field: { value, onChange, onBlur, name } }) => (
                <CustomDropdown
                  id={item?.field_name}
                  name={name}
                  value={value}
                  placeholderName={DYNAMIC_FORM_CONSTANT.SELECT_SERVICES_TYPES?DYNAMIC_FORM_CONSTANT.SELECT_SERVICES_TYPES:"Select Services"}
                  onChange={onChange}
                  handleSelectChange={handleSelectChange}
                  onBlur={onBlur}
                  required={!(item?.mandatory === "false" || !item?.mandatory)}
                  // options={configuration?.COUNTRY_CODE=="PH" && path =="/pages/get-a-quote-form"?dealerData.sort((a,b)=>{return a.name.localeCompare(b.name)}):dealerData}
                  options={JSON.parse(ServicesTypes)}
                  label={item?.display_name}
                  error={errors[item?.field_name]?.message}
                  isSubmitting={isSubmitting}
                  // setdealerconsentadress={setdealerconsentadress}
                />
              )}
            />
            );
            case "existing_suzuki_customer":
              return (
                <>
                  <p
                    className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default marginclass"
                    dangerouslySetInnerHTML={{ __html: item?.display_name }}
                  />
              
                  <div className="flex">
                    {/* First option (Yes) */}
                    <div className="flex justify-start">
                      <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
                        <input
                          id="suzuki_owner_yes"
                          className="w-[18px] h-[21px]"
                          type="radio"
                          value="1"
                          {...register(item?.field_name, {
                            required: !(item?.mandatory === "false" || !item?.mandatory)
                              ? DYNAMIC_FORM_CONSTANT.CLICK_TO_PROCEED
                              : false,
                          })}
                        />
                      </span>
                      <div className="relative w-full">
                        <span className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default marginclass">
                         {
                          DYNAMIC_FORM_CONSTANT?.SUZUKI_OWNER_YES
                         }
                        </span>
                        {errors[item?.field_name] && (
                          <p className="text-[#FF0000] text-[12px] mb-0">
                            {errors[item?.field_name]?.message}
                          </p>
                        )}
                      </div>
                    </div>
              
                    {/* Second option (No) */}
                    <div className="flex justify-start ml-1">
                      <span className="w-[18px] h-[21px] mr-[8px] flex pt-[2px]">
                        <input
                          id="suzuki_owner_no"
                          className="w-[18px] h-[21px]"
                          type="radio"
                          value="0"
                          {...register(item?.field_name, {
                            required: !(item?.mandatory === "false" || !item?.mandatory)
                              ? DYNAMIC_FORM_CONSTANT.CLICK_TO_PROCEED
                              : false,
                          })}
                        />
                      </span>
                      <div className="relative w-full">
                        <span className="text-[14px] self-center text-defaut-color font-suzukiRegular text-default marginclass">
                        {
                          DYNAMIC_FORM_CONSTANT?.SUZUKI_OWNER_NO
                         }
                        </span>
                        {errors[item?.field_name] && (
                          <p className="text-[#FF0000] text-[12px] mb-0">
                            {errors[item?.field_name]?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
              
      default:
        return (
          <InputField
            label={item?.display_name}
            type={item?.field_name === "Phone" || item?.field_name=="vehicle_quantity" ? "number" : item?.field_name}
            required={!(item?.mandatory === "false" || !item?.mandatory)}
            {...register(item?.field_name, {
              required: !(item?.mandatory === "false" || !item?.mandatory)
                ? DYNAMIC_FORM_CONSTANT.REQUIRED_FIELD
                : false,
                validate: (value) => {
                  if (item.field_name === "vehicle_quantity" && value <= 0) {
                    return DYNAMIC_FORM_CONSTANT?.QUANTITY_VALIDATION?DYNAMIC_FORM_CONSTANT?.QUANTITY_VALIDATION:"MINIMUM ONE REQUIRED" ;
                  }
                  return true;
                },
            })}
            placeholder={item?.display_name}
            error={errors[item?.field_name]?.message}
            showTooltip={item?.info && item?.info_text}
            iconSource={`${configuration.IMG_STATIC_URL}assets/images/Tool-tip/infoToolTip.svg`}
            descript={item?.info_text}
          />
        );
    }
  }

  return (
    <div>
      {/* Display a loader while loading */}
      {loading && <Loader />}
      {/* Main container */}
      <div tabIndex={0} className={TestDriveStyle.testdrive__container}>
        <div className="mb-[25px]">
          {/* Display the form title if not hidden */}
          {!hidetitle && <TitleText>{data?.form_title}</TitleText>}
        </div>
        {/* Check if there are fields to display */}
        
        {InputFields && InputFields.length > 0 ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="md:grid grid-cols-2 gap-[25px]">
              {/* Map and render the form fields */}
              {InputFields &&
               InputFields?.filter((field) => field !== null)?.map((item, index) => (
                  <div
                    key={index}
                    className={` relative pb-[20px] md:pb-0 ${
                      item?.field_name == "outletId" && "col-span-2"
                    }
                    ${item?.field_name == "2w_dealer" && "col-span-2"} 
                    ${item?.field_name == "terms" && "col-span-2"} ${
                      item?.field_name == "service_model" &&
                      showOtherModelInput &&
                      "col-span-2"
                    } ${
                      item?.input_type == "checkbox" &&
                      item?.field_name.includes("_consent") &&
                      "col-span-2 pb-0 md:pb-0 lg:pb-0 form-consent-field "
                    }  
                    ${
                      (configuration?.COUNTRY_CODE === "RO" || configuration?.COUNTRY_CODE === "HUN") &&
                      item?.field_name === "remarks"
                        ? "col-span-2"
                        : ""
                    }
                    `}
                  >
                    {/* Render individual input fields */}
                    {item && (
                      <fieldset disabled={loading}>
                        {renderInput(item)}
                      </fieldset>
                    )}
                  </div>
                ))}
              {/* Check if there are consent keys to select all */}
              {consentKeys?.length > 1 && (
                <div className="selectAll-box flex items-center">
                  <span className="w-[18px] h-[21px] mr-[8px]">
                    <input
                      className="w-[18px] h-[21px]"
                      id="selectAllCheckbox"
                      type="checkbox"
                      disabled={loading}
                      checked={selectAll}
                      onChange={(e) => {
                        // Select all consent keys and the "terms" field
                        [
                          ...consentKeys,
                          "terms",
                          "share_personal_details_with_dealer",
                        ].forEach((item) => {
                          setValue(item, e.target.checked);
                        });

                        setSelectAll(true);
                        trigger([
                          ...consentKeys,
                          "terms",
                          "share_personal_details_with_dealer",
                        ]);
                      }}
                    />
                  </span>
                  <div>
                    <label
                      className="relative text-[14px]  self-center text-defaut-color font-suzukiRegular text-default"
                      htmlFor="selectAllCheckbox"
                    >
                      {DYNAMIC_FORM_CONSTANT.SELECT_ALL}
                    </label>
                  </div>
                </div>
              )}

            </div>
               {consentKeys?.length ? (
              <div className="mt-[12px]">
                <p className=" text-[14px] text-defaut-color font-suzukiRegular text-default">
                   {DYNAMIC_FORM_CONSTANT?.MANDOTRYDISCLAIMER || "The consents marked in * are mandatory"}
                </p>
              </div>
            ):''}

            <div className="flex justify-center gap-4 mt-3">
              {/* Display reCAPTCHA if enabled */}
              {data?.enable_captcha && (
                <ReCAPTCHA
                  size="normal"
                  sitekey={data?.api_key}
                  onChange={(e) => {
                    if (e !== "") {
                      setIsCaptchaValid(true);
                    } else {
                      setIsCaptchaValid(false);
                    }
                  }}
                />
              )}
              {/* Submit button */}
              <Button
              id="submitButtonField"
                type="submit"
                className={`text-inverted uppercase border-[1px] border-[#001151] w-[171px] h-[40px] rounded-[90px]  bg-[#001151] text-[13px] ${
                  loading && "opacity-50"
                }
                `}
                disabled={showWaning && warn ? true : false || isFormSubmitted}
              >
                {data?.cta_name || DYNAMIC_FORM_CONSTANT.SUBMIT}
              </Button>
            </div>
          </form>
        ) : (
          <div>
            {/* Display a message when no data is found */}
            <h2 className="text-center">
              {DYNAMIC_FORM_CONSTANT.DATA_NOT_FOUND}
            </h2>
          </div>
        )}
      </div>
      <DevTool control={control} />
    </div>
  );
};

export default DynamicFormComponent;